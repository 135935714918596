import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const SilverLining = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="America's Silver Lining" />
        <meta name="twitter:image" content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png" />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div className="article">
        <Link to="/shoyaright">
        <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>America's Silver Lining</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. May 30, 2020) </p>
        </div>
        <div className="articleBody">
          <div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
          </div>

        </div>
        <p className="plug">If you enjoyed this article, consider <a href="https://www.patreon.com/join/shoyaright?">becoming a patron</a> or making a <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">one-time pledge</a>. Support corporate journalism!</p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020</p>
        </div>
      </div>
    </div>

  );
};
export default SilverLining;
